import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import IconResolver from "./IconResolver";

const pages = {
  pagelinks: [
    {
      id: 1,
      name: "Home",
      link: "/homepage",
      icon: "",
    },
    {
      id: 2,
      name: "Onze visie",
      link: "/visie",
      icon: "",
    },
    {
      id: 3,
      name: "Freinetonderwijs",
      link: "/freinet",
      icon: "",
    },
    {
      id: 4,
      name: "Ouders",
      link: "/ouders",
      icon: "",
    },
    {
      id: 5,
      name: "In de klas",
      link: "/groepen",
      icon: "",
    },
    {
      id: 6,
      name: "Nieuws",
      link: "/nieuws",
      icon: "",
    },
    {
      id: 7,
      name: "Onze school",
      link: "/onzeschool",
      icon: "",
    },
    // {
    //   id: 8,
    //   name: "Vacatures",
    //   link: "/vacatures",
    //   icon: "",
    // },
    {
      id: 9,
      name: "In het nieuws",
      link: "/inhetnieuws",
      icon: "",
    },
    {
      id: 10,
      name: "Aanmelden",
      link: "/aanmelden",
      icon: "",
    },
    {
      id: 11,
      name: "Jaarkalender",
      link: "/kalender",
      icon: "CalendarMonth",
      iconsize: "medium",
      setting: "icon",
    },
    {
      id: 12,
      name: "Contact",
      link: "/contact",
      icon: "Phone",
      iconsize: "medium",
      setting: "icon",
    },
  ],
};

function Menubar({ theme }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.pagelinks.map((page) => (
              <MenuItem
                component={Link}
                to={page.link}
                key={page.id}
                onClick={handleCloseNavMenu}
              >
                <Typography textAlign="center">{page.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.pagelinks.map((page) => (
            <Button
              key={page.id}
              component={Link}
              to={page.link}
              sx={{ my: 2, color: "#000", display: "block" }}
            >
              {page.setting === "icon" ? (
                <IconResolver iconName={page.icon} fontSize={page.iconsize} />
              ) : (
                page.name
              )}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Menubar;
