import React from "react";
import Typography from "@mui/material/Typography";

function Vacatures() {
  return (
    <div style={{ maxWidth: "720px", marginLeft: "10%", marginTop: "10px" }}>
      <Typography variant="h4">Vacatures</Typography>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        Op dit moment hebben wij geen vacatures.
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: 16 }}>
        Op dit moment zijn er geen vacatures. Het kan natuurlijk zijn dat jij
        vindt dat we jou moeten kennen. Dus: wil je nu niet solliciteren, maar
        wel dat wij je kennen, voor wat de toekomst misschien ook brengt? Neem
        dan contact met ons op.
      </Typography>

      <br />
      <img
        src="https://wrassets.boss-tech.nl/beelden/zomerfeest.JPG"
        alt="Zomerfeest - deel van de vieringen die wij kennen binnen ons onderwijs"
        style={{ width: "100%", borderRadius: "5%" }}
        loading="lazy"
      />
    </div>
  );
}

export default Vacatures;
